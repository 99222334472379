<template>
  <div style="height: 100%;" v-loading="loading">
    <div class="div-main-list-page" v-if="view === FINANCE_VIEW.MAIN_FINANCE && !loading">
      <div class="div-main-list-page-title">
        <h1>Financing</h1>
      </div>
      <el-row  class="top-tab-botton-bar" :gutter="10" >
        <el-col :xs="24" :sm="8" :md="10" >
          <el-tabs  style="width:270px"  v-model="tabActive" @tab-click="getTableList(pagination.pageSize, 0)">
            <el-tab-pane
              v-for="item in FINANCE_TAB_LIST"
              :key="item.name"
              :label="item.label"
              :name="item.name"
            />
          </el-tabs>
        </el-col>
        <el-col :xs="24" :sm="16" :md="14" class="flex-end" style="white-space: nowrap" >
          <span
            v-if="isFinancier && tabActive ===FINANCE_TAB_LIST[0].name"
            style="padding-top:5px; white-space: nowrap">
            <el-button
              :disabled="selectedInvoiceRequest.length==0"
              round type="success"
              @click="()=>handleApprove(true)"
            >
              Approve
            </el-button>
          </span>
          <span
            v-if="!isFinancier"
            style="padding-top:5px; white-space: nowrap">
            <el-button
              round type="primary"
              @click="onCreate"
            >
              Create new
            </el-button>
          </span>
        </el-col>
      </el-row>
      <div class="div-main-list-page-table">
        <el-table
          ref="refListTable"
          :data="tableData"
          height="100%"
          row-key='id'
          :border="true"
          :header-cell-style="HEADER_CELL_STYLE"
          :cell-style="CELL_STYLE"
          @selection-change="handleSelectionChange"
        >
          <el-table-column v-if="isFinancier && tabActive === FINANCE_TAB_LIST[0].name" key="checkbox" type="selection" min-width="55"/>
           <el-table-column label="Status" min-width="240px" v-if="tabActive === FINANCE_TAB_LIST[1].name">
            <template v-slot="scope">
                <div
                class="status-tag"
                :style="getStatusTagStyle(scope.row.isOverdue ? 'OVERDUE': scope.row.status )"
              >
                {{ scope.row.isOverdue ? `${scope.row.displayStatus}-Over Due` : scope.row.displayStatus}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Request Number" min-width="150px">
            <template v-slot="scope">
              <p>{{ scope.row.systemGeneratedInvoiceNo ? scope.row.systemGeneratedInvoiceNo :"_"}}</p>
            </template>
          </el-table-column>

          <el-table-column label="Requestor" min-width="100px">
            <template v-slot="scope">
              <p>{{ scope.row.requesterName ? scope.row.requesterName : "_"}}</p>
            </template>
          </el-table-column>
          <el-table-column v-if="!isFinancier" label="Financer" min-width="200px">
            <template v-slot="scope">
              <div
                v-if="scope.row.status === FINANCE_STATUS[0]">
                 <p
                  v-for="item in scope.row.pendingFinanciers"
                  :key="item.id">{{ item.name }}</p>
              </div>
              <p  v-else>{{ scope.row.financier.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Amount" min-width="120px">
            <template v-slot="scope">
              <p>{{ scope.row.amount }}</p>
            </template>
          </el-table-column>
          <el-table-column v-if="isFinancier" label="Platform Fee (%)" min-width="150px">
            <template v-slot="scope">
              <p v-if="scope.row.platformFeePercentage">{{scope.row.platformFeePercentage}}</p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column v-if="isFinancier" label="Platform Fee Amount" min-width="150px">
            <template v-slot="scope">
              <p v-if="scope.row.platformFee">{{scope.row.platformFee}}</p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column v-if="!isFinancier && tabActive !== FINANCE_TAB_LIST[0].name" label="Discount Rate (%)" min-width="150px">
            <template v-slot="scope">
              <p v-if="scope.row.discountRatePercentage">{{scope.row.discountRatePercentage}}</p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column v-if="!isFinancier && tabActive !== FINANCE_TAB_LIST[0].name" label="Receivable Amount" min-width="150px">
            <template v-slot="scope">
              <p v-if="scope.row.amountReceivableAfterDiscountRate">{{scope.row.amountReceivableAfterDiscountRate}}</p>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column v-if="tabActive!==FINANCE_TAB_LIST[0].name" label="Supporting Documents" min-width="160px">
            <template v-slot="scope">
              <p style="color:#1EAD24" v-if="[FINANCE_STATUS[2],FINANCE_STATUS[3],FINANCE_STATUS[4]].includes(scope.row.status)">Uploaded</p>
              <p style="color:#FF2929" v-else>Not Uploaded</p>
            </template>
          </el-table-column>
          <el-table-column v-if="tabActive!==FINANCE_TAB_LIST[0].name" label="MT103" min-width="150px">
            <template v-slot="scope">
               <p style="color:#1EAD24" v-if="[FINANCE_STATUS[3],FINANCE_STATUS[4]].includes(scope.row.status)">Uploaded</p>
              <p style="color:#FF2929" v-else>Not Uploaded</p>
            </template>
          </el-table-column>
          <el-table-column min-width="50px"  fixed="right">
            <template v-slot="scope">
              <dropdown-action-menu
                :actionList="actionList"
                :propsData="scope.row"
                @handleDropdownMenuClick="handleDropdownActionsClick"
              />
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="handlePageSizeChange"
            @current-change="handlePageNumberChange"
            :page-size.sync="pagination.pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            :current-page="pagination.pageNumber"
            layout="total, sizes, prev, pager, next"
            :total="pagination.total"
          />
        </div>
      </div>
    </div>
    <div class="div-main-page" style="height: 100%;" v-if="[FINANCE_VIEW.CREATE_FINANCE,FINANCE_VIEW.EDIT_FINANCE,].includes(view)  && !loading">
      <finance-form
        v-bind="$attrs"
        v-on="$listeners"
        :isFinancier="isFinancier"
        :view.sync="view"
        :formId="formId"
        :systemGeneratedInvoiceNo="systemGeneratedInvoiceNo"
        :requestNo="requestNo"
        @backToMainListPage="refeshMainListPage"
        @handleApprove="handleApprove"
        @reqFinanciersOrSuppliersRegist="reqFinanciersOrSuppliersRegist"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { capitalizeFirstLetter, numberWithCommas, getStatusTagStyle } from '@/utils/index.js'
import { HEADER_CELL_STYLE, CELL_STYLE, FINANCE_VIEW, FINANCE_TAB_LIST, FINANCE_STATUS, FINANCE_STEP_STATUS, COMPANY_TYPE } from '@/constants.js'
import { getFinancingInvoiceList, postMultipleFinanceAction, putSingleFinanceAction, postSuppliersRegister, getFinancersOrSuppliersOrganization } from '@/services/modules/finance.js'
import DropdownActionMenu from '@/components/DropdownActionMenu'
import FinanceForm from './components/FinanceForm.vue'
import { getAllCreditAssociationsCompanyList } from '@/services/modules/comm.js'
export default {
  name: 'FinanceManagement',
  components: { DropdownActionMenu, FinanceForm },
  data () {
    const actionList = [
      // { key: 'edit', label: 'Edit', icon: 'el-icon-edit' },
      { key: 'edit', label: 'View', icon: 'el-icon-view' }
    ]
    const selectedOptions = [
      { label: 'All', value: 'ALL' },
      { label: 'Option 1', value: 'OPTION_ONE' },
      { label: 'Option 2', value: 'OPTION_TWO' },
      { label: 'Option 3', value: 'OPTION_THREE' }
    ]

    return {
      actionList,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      FINANCE_VIEW,
      FINANCE_TAB_LIST,
      FINANCE_STATUS,
      FINANCE_STEP_STATUS,
      COMPANY_TYPE,
      selectedOptions,
      visitRegistDialog: false,
      loading: false,
      view: null,
      tabActive: FINANCE_TAB_LIST[0].name,
      tableData: [],
      formId: null,
      systemGeneratedInvoiceNo: null,
      pagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0
      },
      selectedInvoiceRequest: [],
      requestNo: ''
    }
  },
  created () {
    this.populatInitData()
  },
  computed: {
    ...mapState(['currentCompany', 'financeAccountMe']),
    isFinancier () {
      return (this.currentCompany.companyType === 'Financial service provider') || (this.currentCompany.companyType === this.COMPANY_TYPE.FINANCIAL_SERVICE_PROVIDER)
    }
  },
  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          this.getTableList(this.pagination.pageSize, this.pagination.pageNumber)
        }
      },
      immediate: false
    }
  },
  methods: {
    capitalizeFirstLetter,
    numberWithCommas,
    getStatusTagStyle,
    doLayout () {
      this.$nextTick(() => {
        this.$refs.refListTable.doLayout()
      })
    },
    getFilterStatus () {
      /*
        "PENDING_APPROVAL(0, ""PENDING_APPROVAL"")
        IN_PRINCIPAL_APPROVED(1, ""IN_PRINCIPAL_APPROVED"")
        NOTICE_OF_ASSIGNMENT_ACKNOWLEDGEMENT(2, ""NOTICE_OF_ASSIGNMENT_ACKNOWLEDGEMENT"")
        OUTSTANDING(3, ""OUTSTANDING"")
        COMPLETED(4, ""COMPLETED"")"
      */
      switch (this.tabActive) {
        case this.FINANCE_TAB_LIST[0].name:
          return `status=${this.FINANCE_TAB_LIST[0].status}`
        case this.FINANCE_TAB_LIST[1].name:
          return `status=${this.FINANCE_TAB_LIST[1].status}`
        case this.FINANCE_TAB_LIST[2].name:
          return `status=${this.FINANCE_TAB_LIST[2].status}`
        default:
          return ''
      }
    },
    getApproveOrRejectRespMessage (resp, actionName) {
      const capitalizedActionName = actionName.charAt(0).toUpperCase() + actionName.slice(1)
      if (resp?.code === 1000) {
        this.$message({
          type: 'success',
          message: `${capitalizedActionName} Request Successful!`
        })
        this.refeshMainListPage()
      } else {
        this.$message({
          type: 'error',
          message: `${capitalizedActionName} Request Failed!
${resp.message}`
        })
      }
    },
    onCheckRegisterStatus () {
      const roleName = this.isFinancier ? 'financiers' : 'suppliers'
      getFinancersOrSuppliersOrganization(roleName).then((res) => {
        if (res.code !== 1000) {
          this.reqFinanciersOrSuppliersRegist()
        } else {
          // const storepayload = res.data
          this.$store.commit('setFinanceAccountMe', { ...res.data, isFinancier: this.isFinancier })
        }
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: `Request Failed!
${err}`
        })
      })
    },
    reqFinanciersOrSuppliersRegist () {
      if (this.isFinancier) {
        this.visitRegistDialog = true
      } else {
        const payload = {
          sysOrgId: this.currentCompany.id,
          name: this.currentCompany.companyName
        }
        postSuppliersRegister(payload).then((result) => {
        }).catch((err) => {})
      }
    },
    populatInitData () {
      this.loading = true
      this.view = this.FINANCE_VIEW.MAIN_FINANCE
      this.getTableList(this.pagination.pageSize, this.pagination.pageNumber)
      getAllCreditAssociationsCompanyList(this.currentCompany.id).then(res => {
        this.onCheckRegisterStatus()
        if (res?.code === 1000) {
          const list = res?.data?.content
          if (list?.length > 0) {
            this.$store.commit('setCreditAssociationsCompanyList', list)
          }
        }
        this.loading = false
      })
    },
    getTableList (pageSize, pageNumber) {
      this.loading = true
      const statusFilter = this.getFilterStatus()
      const query = `${statusFilter}&sort=updatedAt DESC`
      getFinancingInvoiceList(pageSize, pageNumber, query).then(res => {
        this.loading = false
        if (res?.code === 1000) {
          this.tableData = res?.data?.content ?? []
          this.tableData.forEach(data => {
            const idx = FINANCE_STEP_STATUS.findIndex(item => item.name === data.status)
            if (idx === 2) {
              data.displayStatus = 'NOA Acknowledgement'
            } else {
              data.displayStatus = FINANCE_STEP_STATUS[idx]?.label
            }
          })
          this.pagination.total = res?.data?.totalElements ?? 0
        } else {
        }
      })
      // this.doLayout()
    },
    onCreate () {
      this.view = FINANCE_VIEW.CREATE_FINANCE
    },
    handlePageNumberChange (pageNumber) {
      this.getTableList(this.pagination.pageSize, pageNumber - 1)
    },
    handlePageSizeChange (pageSize) {
      this.pagination.pageSize = pageSize
      this.getTableList(pageSize, this.pagination.pageNumber)
    },
    editRow (row) {
      this.requestNo = row.requestNo || '_'
      this.view = this.FINANCE_VIEW.EDIT_FINANCE
      this.formId = row.id
      this.systemGeneratedInvoiceNo = row.systemGeneratedInvoiceNo
    },
    handleDropdownActionsClick (action, data) {
      switch (action) {
        case 'edit':
          this.editRow(data, true)
          break
        default:
          break
      }
    },
    handleSelectionChange (selection) {
      this.selectedInvoiceRequest = selection
    },
    async getCalendarByRange (startDate, endDate) {
      try {
        const res = await this.$request.get({
          url: `${this.$apis.calendar}/range?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        })
        if (res?.code === 1000) {
          return res.data.filter(d => d.fullDay)
        }
      } catch (e) {}
    },
    async handMultApproveOrRejectRequest (invoiceIds, actionName) {
      try {
        const res = await postMultipleFinanceAction(invoiceIds, actionName)
        if (res.code) {
          return res
        }
      } catch (error) {
        return error
      }
    },
    async handSingleApproveOrRejectRequest (invoiceIds, actionName) {
      try {
        const res = await putSingleFinanceAction(invoiceIds, actionName)
        if (res.code) {
          return res
        }
      } catch (error) {
        return error
      }
    },
    handleApprove (isApproved) {
      let invoiceIds
      if (this.view === 'MAIN_FINANCE') {
        invoiceIds = this.selectedInvoiceRequest.map(item => item.id)
      }
      if (this.view === 'EDIT_FINANCE') {
        invoiceIds = this.formId
      }
      const actionName = isApproved ? 'approve' : 'reject'
      if (invoiceIds instanceof Array && invoiceIds.length > 1) {
        this.handMultApproveOrRejectRequest(invoiceIds, actionName).then(resp => {
          this.getApproveOrRejectRespMessage(resp, actionName)
        })
      } else {
        const invoiceId = invoiceIds instanceof Array ? invoiceIds[0] : invoiceIds
        this.handSingleApproveOrRejectRequest(invoiceId, actionName).then(resp => {
          this.getApproveOrRejectRespMessage(resp, actionName)
        })
      }
    },
    refeshMainListPage () {
      Object.assign(this.$data, this.$options.data())
      this.view = FINANCE_VIEW.MAIN_FINANCE
      this.tabActive = this.FINANCE_TAB_LIST[0].name
      this.getTableList(this.pagination.pageSize, this.pagination.pageNumber)
    }
  }
}
</script>
<style lang="scss" scoped>
  .top-tab-botton-bar {
  margin-bottom: 8px;
}
/deep/.el-link--inner{
  color:$primary-color;
  font-size: 12px;
}
.pagination-container{
  padding-right: 80px;
}
</style>
